import React from 'react'
import { graphql, useStaticQuery } from 'gatsby'
import { AppBar, Toolbar } from '@material-ui/core'

import Headroom from 'react-headroom'
import AniLink from 'gatsby-plugin-transition-link/AniLink'

export default () => {
  const data = useStaticQuery(graphql`
    {
      siteSettings: sanityPageContent {
        logo {
          asset {
            fluid(maxHeight: 700) {
              ...GatsbySanityImageFluid
            }
          }
        }
        page_transition_logo {
          asset {
            url
          }
        }
      }
    }
  `)
  const settings = data.siteSettings

  return (
    <Headroom>
      <header className="header">
        <AppBar position="static" className="appbar" elevation={0}>
          <Toolbar>
            <AniLink
              className="logo"
              cover
              to="/"
              duration={1.25}
              direction="right"
              bg={`
                url(${settings.page_transition_logo.asset.url})
                center / 50%     /* position / size */
                no-repeat        /* repeat */
                fixed            /* attachment */
                padding-box      /* origin */
                content-box      /* clip */
                #00021a          /* color */
              `}
            >
              <img src={settings.logo.asset.fluid.src} alt="Afwerx Logo" />
            </AniLink>
          </Toolbar>
        </AppBar>
      </header>
    </Headroom>
  )
}
