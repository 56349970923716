import React from 'react'
import { graphql, useStaticQuery } from 'gatsby'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faEnvelope } from '@fortawesome/free-regular-svg-icons'
import { fab } from '@fortawesome/free-brands-svg-icons'
import { fas } from '@fortawesome/free-solid-svg-icons'
import { library } from '@fortawesome/fontawesome-svg-core'

import { mapIndexed } from '../lib/Helpers'

library.add(fab, fas)

export default () => {
  const data = useStaticQuery(graphql`
    query {
      sanityPageContent {
        title
        social_links {
          title
          url
          sponsor
          icon
          type
        }
      }
    }
  `)

  const {
    sanityPageContent: { social_links }
  } = data

  return (
    <footer>
      <div className="container">
        <hr />
        <div style={{ paddingTop: 50 }} />
        <h4 className="text--center title uppercase color--black">
          Brought to you by:
        </h4>
        <div className="sponsors">
          <a
            href="https://www.nasa.gov/"
            target="_blank"
            rel="noopener noreferrer"
          >
            <img
              src={require('../assets/imgs/nasa_logo.svg')}
              alt="nasa logo"
              height="400"
            />
          </a>
          <div className="sponsors--logos">
            {mapIndexed((link, index) => {
              return (
                link.sponsor && (
                  <a
                    key={index}
                    href={link.url}
                    className="link link--white"
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    <FontAwesomeIcon
                      icon={['fab', `${link.icon}`]}
                      className="fb_icon"
                      size="2x"
                      color="black"
                    />
                  </a>
                )
              )
            })(social_links)}
          </div>
        </div>
        <hr />
        <ul className="text--center footer-links">
          {mapIndexed((link, index) => {
            return (
              !link.sponsor && (
                <li key={index}>
                  <a
                    href={link.url}
                    className="link link--black"
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    <FontAwesomeIcon
                      icon={[link.type, link.icon]}
                      mask={['circle']}
                      size="2x"
                    />
                  </a>
                </li>
              )
            )
          })(social_links)}
        </ul>
        <p className="text--center">
          Innovation & Opportunity Conference &copy; 2020 <br /> NASA Small
          Business Innovation Research (SBIR) and Small Business Technology
          Transfer (STTR) Program
        </p>
      </div>
    </footer>
  )
}
