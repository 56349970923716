import * as R from 'ramda'
import React from 'react'
import AniLink from 'gatsby-plugin-transition-link/AniLink'

import { notNilOrEmpty } from '../lib/Helpers'

export default props => {
  return (
    <div
      className={`hero hero--${props.className}`}
      style={{
        background: props.img
          ? `rgb(242,143,63) url(${props.img}) center / 100% no-repeat`
          : 'rgb(242,143,63)'
      }}
    >
      {notNilOrEmpty(props.video) && (
        <video
          className="hero__video-bg"
          playsInline
          autoPlay
          loop
          muted
          src={props.video}
        />
      )}
      {notNilOrEmpty(props.img) && (
        <img
          src={props.img}
          style={{
            objectFit: 'contain',
            width: '100%',
            height: '100%',
            marginBottom: 0,
            visibility: 'hidden'
          }}
          alt="Hero"
        />
      )}
      <div className="hero--overlay" />
      <div className="hero__content container">
        {notNilOrEmpty(props.overlayImg) && (
          <img
            alt="icon in the hero section"
            className={
              R.equals(props.className, 'home-page')
                ? 'hero__content__img'
                : `hero--${props.className}__overlay-image`
            }
            src={props.overlayImg}
          />
        )}
        {notNilOrEmpty(props.ctaBtn) &&
          notNilOrEmpty(props.ctaBtn.cta_btn_url) && (
            <>
              <div style={{ height: 50 }} />
              <AniLink
                cover
                to={props.ctaBtn.cta_btn_url}
                duration={1.25}
                direction="right"
                className="btn btn--hero-register"
                bg={`
                  center / 50% /* position / size */
                  no-repeat        /* repeat */
                  fixed            /* attachment */
                  padding-box      /* origin */
                  content-box      /* clip */
                  #00021a          /* color */
                `}
              >
                {props.ctaBtn.cta_btn_text}
              </AniLink>
            </>
          )}
        {props.title && <h3 className="hero__content__title">{props.title}</h3>}
        {props.subTitle && (
          <p className="hero__content__sub-title">{props.subTitle}</p>
        )}
      </div>
    </div>
  )
}
